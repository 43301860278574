import React, { FC, useCallback, useEffect, useState } from 'react';
import './floor-map.scss';
import { useIonToast, useIonViewDidEnter, useIonViewWillEnter } from '@ionic/react';
import mapboxgl from 'mapbox-gl';
import bbox from '@turf/bbox';
import centroid from '@turf/centroid';
import nearestPointOnLine from '@turf/nearest-point-on-line';
import transformTranslate from '@turf/transform-translate';
import { Feature, Point, Properties, multiLineString, point } from '@turf/helpers';
import getHeatBins from '../../data/heatBins';
import 'mapbox-gl/dist/mapbox-gl.css';
import { titleCase } from '../../common/include';
import HeatLegend from '../HeatLegend';
import { config, HeatBeanObjectKey } from '../../../../config/config';
const { heatBeanRatings } = config;
import { DirectionType } from '../HeatLegend/HeatLegend';
import { FloorMapProps, GeoJson, GeoSlot, IFeatureLabels, IFeatures, Slot } from './IFloormap';
import { InsertGameNameToSlot } from './FloorMapFunctions';
import useMapData from 'components/FloorMapPackage/data/geoJson';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'appRedux/createStore';
import { CasinoInsightsKey, ICasinoReport, IFloorMapCoords } from 'appRedux/models/casinoModels';
import {
  getTopAndBottom,
  getOrderAndHeatKey,
  setHeatKeyForAllItems,
  OrderByEnum,
  slotHotnessScale,
  hexToRgba,
  sortByInsightOrder,
} from 'common/common';
import { FloormapReduxCommandCreator } from 'appRedux/actions/floormapCommandCreator';
import { casinoIdListEnum } from 'common/commonEnum';
import { selectHeatFilterAndBearing } from 'appRedux/selectors';
import SVGComponent from 'components/SmartLoading/SVGComponent';
import { RWCIconArr } from 'components/FloorMapPackage/data/geoJson/rwc/mapboxImageLoad';

const slotLocationIdLookup = new Map();

const FloorMap: FC<FloorMapProps> = ({
  mapId,
  slotData,
  slotDataIsLoading,
  individualSlotNumber,
  selectedInsight,
  isShowData,
  locationLatLon,
  selectedSlots,
  onClickSlot,
  onClickHeatFilter,
  onFloorStats,
  shouldHideSlotMark,
  previousSelectedSlotNumber,
  geoJsonData,
  getGeoJsonLoading,
  slotMetaData,
}) => {
  const [mapMarkers, setMapMarkers] = useState<any[]>([]);
  const [presentMapError] = useIonToast();
  const [heatBins, setHeatBins] = useState<any>();
  const [markerSlots, setMarkerSlots] = useState<any>();
  const [markerColdestSlots, setMarkerColdestSlots] = useState<any>();
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  mapboxgl.accessToken = config.mapbox.key;

  const dispatch = useDispatch();
  const floormapCommand = FloormapReduxCommandCreator(dispatch);

  const isTopTenSlot = useSelector<IState>(
    (state) => state.app.persistedState.isSlotOrderAscending
  ) as boolean;

  const casinoId = useSelector<IState>(
    (state) => state.app.persistedState.casinoSchema?.kpCasinoPk
  ) as number;
  const floorMapCenter = useSelector<IState>(
    (state) => state.app.persistedState.casinoSchema?.floorMaps.center
  ) as IFloorMapCoords;

  const mapContainer = React.useRef<any>(null);
  const [map, setMap] = useState<any>(undefined);
  const [slotMapJsonWithIds, setSlotMapJsonWithIds] = useState<any>(undefined);
  const [isMapInitialized, setIsMapInitialized] = useState(false);
  const { wallMapData, bankIdMapData, labelMapData, slotsMapData } = useMapData(casinoId);
  const [wallMapJson, setWallMapJson] = useState<GeoJson>();
  const [bankIdMapJson, setBankIdMapJson] = useState<GeoJson>();
  const [labelMapJson, setLabelMapJson] = useState<GeoJson>();

  const [mapBounds, setMapBounds] = useState<number[] | undefined>(undefined);
  const [mapCenter, setMapCenter] = useState<Feature<Point, Properties> | undefined>(undefined);
  const [maxBounds, setMaxBounds] = useState<mapboxgl.LngLatBoundsLike | undefined>(undefined);

  const addMapImage = useMapData(casinoId).addMapImage;
  const addMapRWCImage = useMapData(casinoId).addMapRWCImage;
  const addMapEurekaImage = useMapData(casinoId).addMapEurekaImage;

  const mapBoundsMarginLng = 0.005;
  const mapBoundsMarginLat = 0.009;

  const [displayLegend, setDisplayLegend] = useState(false);
  const tooltip = new mapboxgl.Popup({
    closeOnClick: true,
  });
  const { heatFilter, bearing } = useSelector(selectHeatFilterAndBearing);
  let individualSlotLngLat: any;

  useEffect(() => {
    wallMapData && setWallMapJson(wallMapData as GeoJson);
    bankIdMapData && setBankIdMapJson(bankIdMapData as GeoJson);
    labelMapData && setLabelMapJson(labelMapData as GeoJson);
    slotsMapData && setSlotMapJsonWithIds(slotsMapData);

    if (wallMapData) {
      const mapBounds = bbox(wallMapData);
      const mapCenter = centroid(wallMapData);
      const maxBounds: mapboxgl.LngLatBoundsLike = [
        [mapBounds[0] - mapBoundsMarginLng, mapBounds[1] - mapBoundsMarginLat],
        [mapBounds[2] + mapBoundsMarginLng, mapBounds[3] + mapBoundsMarginLat],
      ];
      setMapCenter(mapCenter);
      setMaxBounds(maxBounds);
    }
  }, [wallMapData, bankIdMapData, labelMapData, slotsMapData]);

  const getJsonData = useCallback(() => {
    if (map) {
      ['wall', 'label', 'bankId'].forEach((source) => {
        if (map.getSource(`${source}Source`)) {
          map.getSource(`${source}Source`).setData({
            type: 'FeatureCollection',
            features: eval(`${source}MapJson`).features,
          });
        }
      });
    }
    if (wallMapJson) {
      if (map) {
        const mapBounds = bbox(wallMapJson);
        const mapCenter = centroid(wallMapJson);

        const maxBounds: mapboxgl.LngLatBoundsLike = [
          [mapBounds[0] - mapBoundsMarginLng, mapBounds[1] - mapBoundsMarginLat],
          [mapBounds[2] + mapBoundsMarginLng, mapBounds[3] + mapBoundsMarginLat],
        ];
        const newCenter = [mapCenter.geometry.coordinates[1], mapCenter.geometry.coordinates[1]];
        setMapBounds(newCenter);
        map?.setCenter(newCenter);
        map?.setMaxBounds(maxBounds);
      }

      flyToMapCenter(mapCenter?.geometry?.coordinates, 14);
    }
  }, [casinoId]);

  useEffect(() => {
    getJsonData();
  }, [getJsonData]);

  const processData = (
    slotData: ICasinoReport[],
    selectedInsight: CasinoInsightsKey
  ): ICasinoReport[] => {
    return slotData.map((slot) => {
      let updatedSlot = { ...slot };

      const playDataSlot = slotData.find(
        (playSlot) =>
          updatedSlot.area === playSlot.area &&
          updatedSlot.section === playSlot.section &&
          updatedSlot.location === playSlot.location
      );

      if (playDataSlot) {
        const insightValue = playDataSlot[selectedInsight];
        if (insightValue !== undefined) {
          updatedSlot[selectedInsight] = insightValue;
        } else {
          updatedSlot[selectedInsight] = 0;
        }
      } else {
        updatedSlot[selectedInsight] = 0;
      }

      return updatedSlot;
    });
  };

  useEffect(() => {
    if (!slotDataIsLoading && slotData !== undefined) {
      const processedData = processData(slotData, selectedInsight);
      setHeatBins(getHeatBins(processedData, selectedInsight));
      setMarkerSlots([]);
      setMarkerColdestSlots([]);

      if (selectedSlots && selectedSlots?.length > 0) {
        const selectedMarkerSlots = selectedSlots
          .map((selectedSlotNumber: number) => {
            const foundSlot = processedData.find(
              (slot) => String(slot.slot_number) === String(selectedSlotNumber)
            );
            return foundSlot ? { ...foundSlot, ...getOrderAndHeatKey(0, OrderByEnum.Asc) } : null;
          })
          .filter(Boolean);
        const orderedSelectedMarkerSlots = sortByInsightOrder(
          selectedMarkerSlots as ICasinoReport[],
          selectedInsight,
          isTopTenSlot ? OrderByEnum.Desc : OrderByEnum.Asc
        );

        setMarkerSlots(orderedSelectedMarkerSlots);
      } else {
        const { top10, bottom10 } = getTopAndBottom(processedData, selectedInsight);
        const allTopAndBottomSlots = [
          ...top10.map((slot, index) => ({
            ...slot,
            ...getOrderAndHeatKey(index, OrderByEnum.Desc),
          })),
          ...(selectedInsight === 'jackpots' || selectedInsight === 'jackpot_value'
            ? setHeatKeyForAllItems(top10, slotHotnessScale.Six)
            : []),
          ...bottom10.map((slot, index) => ({
            ...slot,
            ...getOrderAndHeatKey(index, OrderByEnum.Asc),
          })),
        ];
        setMarkerSlots(allTopAndBottomSlots.slice(0, 10));
        if (!(selectedInsight === 'jackpots' || selectedInsight === 'jackpot_value')) {
          setMarkerColdestSlots(allTopAndBottomSlots.slice(-10));
        }
      }
    }
  }, [
    slotData,
    slotDataIsLoading,
    selectedInsight,
    selectedSlots,
    previousSelectedSlotNumber,
    isTopTenSlot,
  ]);

  const [loadError, setLoadError] = useState(false);

  const displayMapError = (): void => {
    setIsDataLoaded(true);
    setLoadError(true);
    void presentMapError({
      message: 'Grumble... we ran into an issue loading the map data. Sorry!',
      duration: 3000,
    });
  };

  useEffect(() => {
    if (geoJsonData && !getGeoJsonLoading) {
      if (
        geoJsonData.DataSet?.length > 0 &&
        geoJsonData.DataSet[0]?.geojson?.features?.length > 0
      ) {
        const clonedJson = JSON.parse(JSON.stringify(geoJsonData.DataSet[0].geojson));
        clonedJson.features.forEach((slot: GeoSlot) => {
          slot.id = slot.properties.kp_floor_plan_location_pk;
          const locationKey = `${slot.properties.area}${slot.properties.section}${slot.properties.location}`;
          slotLocationIdLookup.set(locationKey, slot.id);
        });
        setSlotMapJsonWithIds(clonedJson);
      } else {
        displayMapError();
      }
    }
  }, [geoJsonData, getGeoJsonLoading]);

  const clearMapMarkers = (): void => {
    map?.fire('closeAllPopups');
    mapMarkers.forEach((marker) => marker._element.remove());
    setMapMarkers([]);
  };

  const clearHeatValues = (): void => {
    slotMapJsonWithIds &&
      slotMapJsonWithIds.features.forEach((feature: IFeatures) => {
        if (feature.id !== undefined && feature.id !== null) {
          map.setFeatureState(
            {
              source: 'slotSource',
              id: feature.id,
            },
            {
              heat: 0,
            }
          );
        }
      });
  };

  const findTargetGeoSlot = (slotDatum: any): GeoSlot | undefined => {
    return (
      slotMapJsonWithIds &&
      slotMapJsonWithIds.features.find(
        (geoSlot: GeoSlot) =>
          geoSlot.id ===
          slotLocationIdLookup.get(slotDatum.area + slotDatum.section + slotDatum.location)
      )
    );
  };

  const findIndividualSlot = (slotData: ICasinoReport[], slotNumber: number) => {
    return slotData?.find((slot) => slot.slot_number == slotNumber);
  };

  const findPlayDataSlot = (evt: any, slotData: ICasinoReport[]): ICasinoReport | undefined => {
    const feature = evt?.features?.[0]?.properties;

    if (!feature || !slotData) {
      return undefined;
    }
    return slotData.find(
      (playSlot: ICasinoReport) =>
        feature.area === playSlot.area &&
        feature.section === playSlot.section &&
        feature.location === playSlot.location
    );
  };

  const createMarkerWithStar = (
    slotCentroid: any,
    borderLocation: any,
    markerNumber: number
  ): mapboxgl.Marker => {
    // Create a HTML element for each feature
    const el = document.createElement('div');
    el.className = 'mapboxgl-marker';

    const starDiv = document.createElement('div');
    starDiv.className = 'star';
    el.appendChild(starDiv);

    // Move the marker up/North by the distance between center and border divided by 1.5
    const markerLocation = transformTranslate(
      slotCentroid,
      borderLocation.properties.dist / 1.5,
      0
    );

    // Create a marker for the individual slot with a star
    return new mapboxgl.Marker(el).setLngLat([
      markerLocation.geometry.coordinates[0],
      markerLocation.geometry.coordinates[1],
    ]);
  };

  const createMarker = (
    slotDatum: any,
    markerNumber: number,
    markerClass: string,
    bgColor: string
  ): mapboxgl.Marker | undefined => {
    const targetGeoSlot: any = findTargetGeoSlot(slotDatum);
    if (!targetGeoSlot || slotDatum[selectedInsight] <= -1) {
      return undefined;
    }

    const el = document.createElement('div');
    el.className = markerClass;

    if (markerNumber === 1) {
      el.setAttribute('home-page-tour-step', 'number-1-slot-map');
    }

    if (markerClass !== 'mapboxgl-marker-transparent') {
      el.setAttribute('style', `--bg-color: ${bgColor}`);
      el.innerHTML = `${markerNumber}`;
    }
    if (targetGeoSlot) {
      const slotCentroid = centroid(targetGeoSlot);
      const borderLocation: any = nearestPointOnLine(
        multiLineString(targetGeoSlot.geometry.coordinates),
        point(slotCentroid.geometry.coordinates)
      );

      const markerLocation = transformTranslate(
        slotCentroid,
        borderLocation.properties.dist / 1.5,
        0
      );

      return new mapboxgl.Marker(el).setLngLat([
        markerLocation.geometry.coordinates[0],
        markerLocation.geometry.coordinates[1],
      ]);
    }
  };

  const addMarkersToMap = (markers: mapboxgl.Marker[]): void => {
    for (let i = markers.length - 1; i >= 0; i--) {
      markers[i].addTo(map);
    }
  };

  const handleIndividualSlot = (
    slotData: ICasinoReport[],
    individualSlotNumber: number,
    showMarker: boolean
  ) => {
    const individualSlot = findIndividualSlot(slotData, individualSlotNumber);
    if (individualSlot) {
      const individualSlotTargetGeoSlot =
        slotMapJsonWithIds &&
        slotMapJsonWithIds.features.find(
          (geoSlot: GeoSlot) =>
            geoSlot.id ===
            slotLocationIdLookup.get(
              individualSlot.area + individualSlot.section + individualSlot.location
            )
        );
      if (individualSlotTargetGeoSlot !== undefined) {
        const slotCentroid = centroid(individualSlotTargetGeoSlot);
        const marker = createMarkerWithStar(
          centroid(individualSlotTargetGeoSlot),
          nearestPointOnLine(
            multiLineString(individualSlotTargetGeoSlot.geometry.coordinates),
            point(slotCentroid.geometry.coordinates)
          ),
          1
        );
        const markerLngLat = marker.getLngLat();
        const markerLongitude = markerLngLat.lng;
        const markerLatitude = markerLngLat.lat;
        individualSlotLngLat = new mapboxgl.LngLat(markerLongitude, markerLatitude);
        flyToMapCenter(
          individualSlotLngLat,
          selectedSlots.length > 1 || individualSlot ? 16.5 : 18
        );
        if (showMarker) {
          setMapMarkers((prevMarkers) => [...prevMarkers, marker]);
        }
      }
    }
  };

  const coldestSlots = (): void => {
    let markerNumber = 10;
    const markers: mapboxgl.Marker[] = [];

    markerColdestSlots.forEach((slotDatum: any) => {
      const marker = createMarker(
        slotDatum,
        markerNumber,
        'mapboxgl-marker mapboxgl-cold',
        '#E32D91'
      );
      if (marker) {
        setMapMarkers((prevMarkers) => [...prevMarkers, marker]);
        markers.push(marker);
        markerNumber--;
        const markerLngLat = marker.getLngLat();
        const markerLongitude = markerLngLat.lng;
        const markerLatitude = markerLngLat.lat;
        if (individualSlotNumber === slotDatum.slot_number || selectedSlots.length > 1) {
          individualSlotLngLat = new mapboxgl.LngLat(markerLongitude, markerLatitude);
          if (individualSlotLngLat) {
            flyToMapCenter(individualSlotLngLat, selectedSlots.length > 1 ? 16.5 : 18);
          }
        }
      }
    });

    addMarkersToMap(markers);
  };

  const HottestSlots = (): void => {
    let markerNumber = 1;
    const markerValueMin = markerSlots.length > 1 ? -1 : -1;
    const markers: mapboxgl.Marker[] = [];
    markerSlots.forEach((slotDatum: any) => {
      const marker = createMarker(
        slotDatum,
        markerNumber,
        markerSlots.length !== 1 ? 'mapboxgl-marker mapboxgl-hot' : 'mapboxgl-marker-transparent',
        '#0F172E'
      );
      if (marker) {
        markers.push(marker);
        setMapMarkers((prevMarkers) => [...prevMarkers, marker]);
        markerNumber++;
        const markerLngLat = marker.getLngLat();
        const markerLongitude = markerLngLat.lng;
        const markerLatitude = markerLngLat.lat;
        if (individualSlotNumber === slotDatum.slot_number || selectedSlots.length > 1) {
          individualSlotLngLat = new mapboxgl.LngLat(markerLongitude, markerLatitude);
          if (individualSlotLngLat) {
            flyToMapCenter(individualSlotLngLat, selectedSlots.length > 1 ? 16.5 : 18);
          }
        }
      }
    });

    addMarkersToMap(markers);
  };

  useEffect(() => {
    if (map) {
      setPreferenceHeatMapColor();
    }
  }, [heatFilter]);

  const updateMapData = (): void => {
    if (!map || !heatBins || !map.getSource('slotSource')) return;
    clearMapMarkers();
    clearHeatValues();
    insertGameNames();

    const totalSlots = {
      total: slotData,
      beanData: heatBins,
    };

    if (onFloorStats) {
      onFloorStats(totalSlots);
    }

    Object.keys(heatBins).forEach((key: any) => {
      heatBins[key].forEach((slot: Slot) => {
        const slotId = slotLocationIdLookup.get(slot.area + slot.section + slot.location);
        if (slotId !== undefined) {
          map.setFeatureState(
            {
              source: 'slotSource',
              id: slotId,
            },
            {
              heat: parseInt(key),
            }
          );
        }
      });
    });

    setPreferenceHeatMapColor();
    if (!shouldHideSlotMark && individualSlotNumber && !previousSelectedSlotNumber && slotData) {
      handleIndividualSlot(slotData, individualSlotNumber, true);
    } else {
      if (selectedSlots.length === 0) {
        if (isTopTenSlot) {
          HottestSlots();
        }

        if (selectedInsight !== 'jackpots' && selectedInsight !== 'jackpot_value') {
          if (!isTopTenSlot) {
            coldestSlots();
          }
        }
      } else {
        HottestSlots();
      }
    }

    const zoomLevel = individualSlotLngLat ? (selectedSlots?.length > 1 ? 10 : 18) : 10;
    flyToMapCenter(
      individualSlotLngLat || new mapboxgl.LngLat(floorMapCenter.x, floorMapCenter.y),
      zoomLevel
    );
    floormapCommand.SetZoomAction(individualSlotLngLat ? 18 : 10);
    map.on('click', 'slotLayer', (evt: any) => {
      if (evt?.features?.[0]?.properties && slotData) {
        const playDataSlot = findPlayDataSlot(evt, slotData);
        if (playDataSlot) {
          const playData = playDataSlot[selectedInsight];
          individualSlotNumber = playDataSlot.slot_number;
          onClickSlot(playDataSlot);
        }
      }
    });

    map.on('mousemove', 'slotLayer', (evt: any) => {
      if (evt?.features?.[0]?.properties) {
        map.getCanvas().style.cursor = 'pointer';
      }
    });

    map.on('mouseleave', 'slotLayer', () => {
      map.getCanvas().style.cursor = '';
    });

    map.on('closeAllPopups', () => {
      tooltip.remove();
    });

    if (bearing) {
      floormapCommand.SetBearingAction(bearing);
    }

    map.on('dragend', () => {
      floormapCommand.setMapValues({
        bearing: map.getBearing(),
        latitudeLongitude: map.getCenter(),
        zoom: map.getZoom(),
      });
    });

    map.on('zoom', () => {
      if (!individualSlotNumber) {
        floormapCommand.setMapValues({
          bearing: map.getBearing(),
          latitudeLongitude: map.getCenter(),
          zoom: map.getZoom(),
        });
      }
    });

    map.on('rotate', () => {
      floormapCommand.setMapValues({
        bearing: map.getBearing(),
        latitudeLongitude: map.getCenter(),
        zoom: map.getZoom(),
      });
    });
  };

  useEffect(() => {
    if (map) {
      for (let i = mapMarkers.length - 1; i >= 0; i--) {
        mapMarkers[i].addTo(map);
      }
    }
  }, [mapMarkers]);

  useEffect(() => {
    if (map?.getSource('wallSource')) {
      map.getSource('wallSource').setData({
        type: 'FeatureCollection',
        features: wallMapJson?.features || [],
      });
    }

    // Update 'labelSource' features
    if (map?.getSource('labelSource')) {
      map.getSource('labelSource').setData({
        type: 'FeatureCollection',
        features: labelMapJson?.features || [],
      });
    }

    // Update 'bankIdSource' features
    if (map?.getSource('bankIdSource')) {
      map.getSource('bankIdSource').setData({
        type: 'FeatureCollection',
        features: bankIdMapJson?.features || [],
      });
    }
  }, [map, wallMapJson, labelMapJson, bankIdMapJson]);

  const flyToMapCenter = (locationLngLat: any, zoomValue: any): void => {
    map &&
      map.flyTo({
        center: locationLngLat,
        zoom: zoomValue,
      });
  };

  const setGameNameProperties = (targetGeoSlot: GeoSlot, slot: any): void => {
    if (slot.slot_number !== 0) {
      const appendText = InsertGameNameToSlot(selectedInsight, slot, slotData);
      targetGeoSlot.properties.game_name = appendText;
    } else {
      targetGeoSlot.properties.game_name = `${titleCase(slot.game_name)}`;
    }
  };

  const insertGameNameIntoGeoJson = (slot: any): void => {
    const targetGeoSlot = findTargetGeoSlot(slot);
    if (targetGeoSlot) {
      setGameNameProperties(targetGeoSlot, slot);
    }
  };

  const updateSlotSourceData = (): void => {
    if (map?.getSource('slotSource') !== undefined && slotMapJsonWithIds) {
      map.getSource('slotSource').setData({
        type: 'FeatureCollection',
        features: slotMapJsonWithIds.features,
      });
    }
  };

  const insertGameNames = (): void => {
    if (slotMetaData?.DataSet !== undefined) {
      slotMetaData.DataSet.forEach((slot: Slot) => {
        insertGameNameIntoGeoJson(slot);
      });
    }

    if (heatBins !== undefined) {
      Object.keys(heatBins).forEach((key) => {
        heatBins[key].forEach((slot: Slot) => {
          insertGameNameIntoGeoJson(slot);
        });
      });

      updateSlotSourceData();
    }
  };

  const onResize = useCallback(() => {
    let isMounted = true;
    map?.once('load', () => {
      map?.resize();
    });
    map?.on('idle', () => {
      if (isMounted) {
        setIsDataLoaded(true);
      }
      map?.resize();
    });
    map?.on('render', () => {
      map?.resize();
    });
    map?.on('style.load', () => {
      map?.resize();
    });
    map?.on('load', () => {
      map?.resize();
    });
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 0);
    return () => {
      isMounted = false;
    };
  }, [map]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      onResize();
    }

    return () => {
      isMounted = false;
    };
  }, [onResize]);

  useIonViewWillEnter(() => {
    onResize();
  });

  useIonViewDidEnter(() => {
    updateMapData();
  });

  useEffect(() => {
    const allDataLoaded = wallMapJson && bankIdMapJson && labelMapJson && slotMapJsonWithIds;
    if (allDataLoaded) {
      getInitializeMap();
    }
  }, [wallMapJson, bankIdMapJson, labelMapJson, slotMapJsonWithIds]);

  const getInitializeMap = useCallback(() => {
    const initializeMap = (): void => {
      const mapCenterLng = locationLatLon
        ? locationLatLon.lat
        : mapCenter?.geometry.coordinates[0] ?? 0; // Default to 0 if undefined
      const mapCenterLat = locationLatLon
        ? locationLatLon.lon - 0.00175
        : mapCenter?.geometry.coordinates[1] ?? 0; // Default to 0 if undefined

      const mapbox = new mapboxgl.Map({
        container: mapContainer.current,
        style: config.mapbox.styleUrl, // style URL
        center: [mapCenterLng, mapCenterLat], // starting position [lng, lat]
        zoom: locationLatLon ? 16 : 13, // starting zoom
        minZoom: 10,
        maxZoom: 24,
        maxBounds: maxBounds,
      });
      mapbox?.resize();
      mapbox.on('load', () => {
        // Add slots geoJSON
        mapbox.resize();
        mapbox.addSource('slotSource', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: slotMapJsonWithIds && slotMapJsonWithIds.features,
          },
        });

        // Add zoom buttons
        mapbox.addControl(new mapboxgl.NavigationControl());

        // Display slots on a layer
        mapbox.addLayer({
          id: 'slotLayer',
          type: 'fill',
          source: 'slotSource',
          layout: {},
          paint: {
            'fill-color': [
              'case',
              ['match', ['feature-state', 'heat'], [6], true, false],
              heatBeanRatings[6].color,
              ['match', ['feature-state', 'heat'], [5], true, false],
              heatBeanRatings[5].color,
              ['match', ['feature-state', 'heat'], [4], true, false],
              heatBeanRatings[4].color,
              ['match', ['feature-state', 'heat'], [3], true, false],
              heatBeanRatings[3].color,
              ['match', ['feature-state', 'heat'], [2], true, false],
              heatBeanRatings[2].color,
              ['match', ['feature-state', 'heat'], [1], true, false],
              heatBeanRatings[1].color,
              heatBeanRatings[0].color,
            ],
            'fill-outline-color': '#000000',
          },
        });

        // Draw thicker borders between slots
        mapbox.addLayer({
          id: 'slotBorderLayer',
          type: 'line',
          source: 'slotSource',
          layout: {},
          paint: { 'line-color': 'hsl(222, 8%, 23%)', 'line-width': 1 },
        });

        if (wallMapJson?.features) {
          mapbox.addSource('wallSource', {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: wallMapJson?.features,
            },
          });
        }

        if (labelMapJson?.features) {
          labelMapJson.features.forEach((feature: IFeatureLabels) => {
            if (feature.properties && feature.properties.autocad_text_string) {
              feature.properties.autocad_text_string =
                feature.properties.autocad_text_string.replace(/\\n/g, '\n');
            }
          });
          mapbox.addSource('labelSource', {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: labelMapJson?.features,
            },
          });
        }

        if (bankIdMapJson?.features) {
          mapbox.addSource('bankIdSource', {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: bankIdMapJson?.features,
            },
          });
        }

        // Add BankID
        if (casinoId === casinoIdListEnum.LOTC || casinoId === casinoIdListEnum.EUREKA) {
          mapbox.addLayer({
            id: 'bankIdLayer',
            type: 'symbol',
            source: 'bankIdSource',
            layout: {
              'text-field': ['to-string', ['get', 'name']],
              'text-size': [
                'interpolate',
                ['linear'],
                ['zoom'],
                16,
                10,
                17,
                20,
                18,
                30,
                19,
                45,
                20,
                60,
                24,
                75,
              ],
              'text-justify': 'left',
              'text-anchor': 'left',
              'text-padding': 0,
              'text-font': ['Open Sans Bold', 'Arial Unicode MS Regular'],
            },
            paint: { 'text-color': '#f7f5f7', 'text-opacity': 1 },
          });
        }

        if (casinoId === casinoIdListEnum.RWC) {
          mapbox.addLayer({
            id: 'bankIdLayerRWC',
            type: 'line',
            source: 'bankIdSource',
            layout: {},
            paint: {
              'line-color': '#fff',
              'line-width': [
                'case',
                ['==', ['get', 'autocad_block_name'], 'ATM'],
                1,
                ['==', ['get', 'autocad_block_name'], 'Kiosk'],
                1,
                ['==', ['get', 'autocad_block_name'], 'Cashier'],
                1,
                ['==', ['get', 'autocad_block_name'], 'Cashier_walkway'],
                1,
                0,
              ],
            },
          });

          mapbox.addLayer({
            id: 'bankIdLabelLayerRWC',
            type: 'symbol',
            source: 'bankIdSource',
            layout: {
              'text-field': ['to-string', ['get', 'autocad_text_string']],
              'text-padding': 0,
              'text-font': ['Open Sans Bold', 'Arial Unicode MS Regular'],
              'text-offset': [0, 0],
              'text-anchor': 'center',
              'text-justify': 'center',
              'text-size': [
                'interpolate',
                ['linear'],
                ['zoom'],
                13,
                3,
                14,
                3,
                15,
                9,
                16,
                15,
                17,
                24,
              ],
              'text-rotation-alignment': 'map',
              'text-rotate': ['get', 'autocad_rotation'],
            },
            paint: { 'text-color': '#f7f5f7', 'text-opacity': 1 },
          });
        }

        // Add walls
        mapbox.addLayer({
          id: 'wallLayer',
          type: 'line',
          source: 'wallSource',
          layout: {},
          paint: {
            'line-color': [
              'match',
              ['get', 'autocad_block_name'],
              'Poker_Table',
              heatBeanRatings[0].color,
              'Roulette_Table',
              heatBeanRatings[0].color,
              'Craps_Table',
              heatBeanRatings[0].color,
              'Sports_Bedding',
              heatBeanRatings[0].color,
              '#fff',
            ],
            'line-width': 1.2,
          },
        });

        if (casinoId === casinoIdListEnum.LOTC) {
          addMapImage(mapbox);
          mapbox.addLayer({
            id: 'labelLayer',
            type: 'symbol',
            source: 'labelSource',
            layout: {
              'icon-image': ['to-string', ['get', 'icon']],
              'icon-size': [
                'interpolate',
                ['linear'],
                ['zoom'],
                13,
                0.1,
                14,
                0.11,
                15,
                0.22,
                16,
                0.33,
                17,
                0.6,
                18,
                1.2,
                19,
                1.6,
              ],
              'text-variable-anchor': ['top'],
              'text-radial-offset': [
                'interpolate',
                ['linear'],
                ['zoom'],
                13,
                1.5,
                14,
                1.5,
                15,
                1.5,
                16,
                1.8,
                17,
                4,
                18,
                4,
                19,
                4,
              ],
              'text-field': ['to-string', ['get', 'text']],
              'text-size': [
                'interpolate',
                ['linear'],
                ['zoom'],
                13,
                10,
                14,
                10,
                15,
                10,
                16,
                20,
                17,
                20,
                18,
                30,
                19,
                45,
                20,
                60,
                24,
                75,
              ],
              'text-justify': 'auto',
              'text-anchor': 'left',
              'text-padding': 0,
              'text-font': ['Open Sans Bold', 'Arial Unicode MS Regular'],
            },
            paint: { 'text-color': '#f7f5f7', 'text-opacity': 0.5 },
          });
        }

        if (casinoId === casinoIdListEnum.EUREKA) {
          addMapEurekaImage(mapbox);
          mapbox.addLayer({
            id: 'lineLayerEureka',
            type: 'line',
            source: 'labelSource',
            layout: {},
            paint: { 'line-color': '#fff', 'line-width': 0 },
          });

          mapbox.addLayer({
            id: 'labelLayerEureka',
            type: 'symbol',
            source: 'labelSource',
            layout: {
              'icon-image': ['to-string', ['get', 'autocad_block_name']],
              'icon-size': [
                'interpolate',
                ['linear'],
                ['zoom'],
                13,
                0.1,
                14,
                0.11,
                15,
                0.22,
                16,
                0.33,
                17,
                0.6,
                18,
                1.2,
                19,
                1.6,
              ],
              'icon-anchor': 'center',
              'icon-offset': [0, 0],
              'icon-allow-overlap': false,
              'icon-rotation-alignment': 'map',
              'text-allow-overlap': true,
              'text-field': ['to-string', ['get', 'autocad_text_string']],
              'text-padding': 0,
              'text-font': ['Open Sans Bold', 'Arial Unicode MS Regular'],
              'text-offset': [0, 0],
              'text-anchor': 'center',
              'text-justify': 'center',
              'text-size': [
                'interpolate',
                ['linear'],
                ['zoom'],
                13,
                8,
                14,
                8,
                15,
                11,
                16,
                15,
                17,
                24,
              ],
              'text-rotation-alignment': 'map',
              'text-rotate': ['get', 'autocad_rotation'],
            },
            paint: { 'text-color': '#f7f5f7', 'text-opacity': 0.5 },
          });
        }

        if (casinoId === casinoIdListEnum.RWC) {
          addMapRWCImage(mapbox);
          mapbox.addLayer({
            id: 'labelLayerLineRWC',
            type: 'line',
            source: 'labelSource',
            layout: {},
            paint: { 'line-color': '#fff', 'line-width': 0 },
          });

          mapbox.addLayer({
            id: 'labelLayerRWC',
            type: 'symbol',
            source: 'labelSource',
            layout: {
              'icon-image': [
                'case',
                [
                  'in',
                  ['get', 'autocad_block_name'],
                  ['literal', RWCIconArr.map((item) => item.name)],
                ],
                ['to-string', ['get', 'autocad_block_name']],
                '',
              ],
              'icon-size': [
                'interpolate',
                ['linear'],
                ['zoom'],
                13,
                0.1,
                14,
                0.11,
                15,
                0.22,
                16,
                0.33,
                17,
                0.6,
                18,
                1.2,
                19,
                1.6,
              ],
              'icon-anchor': 'center',
              'icon-offset': [0, 0],
              'icon-allow-overlap': false,
              'icon-rotation-alignment': 'map',
              'text-allow-overlap': true,
              'text-field': ['to-string', ['get', 'autocad_text_string']],
              'text-padding': 0,
              'text-font': ['Open Sans Bold', 'Arial Unicode MS Regular'],
              'text-offset': [0, 0],
              'text-anchor': 'center',
              'text-justify': 'center',
              'text-size': [
                'interpolate',
                ['linear'],
                ['zoom'],
                12,
                ['case', ['==', ['get', 'autocad_text_string'], 'Cashier'], 6, 8],
                13,
                ['case', ['==', ['get', 'autocad_text_string'], 'Cashier'], 6, 8],
                14,
                ['case', ['==', ['get', 'autocad_text_string'], 'Cashier'], 13, 8],
                15,
                ['case', ['==', ['get', 'autocad_text_string'], 'Cashier'], 21, 11],
                16,
                ['case', ['==', ['get', 'autocad_text_string'], 'Cashier'], 40, 15],
                17,
                ['case', ['==', ['get', 'autocad_text_string'], 'Cashier'], 64, 24],
              ],
              'text-rotation-alignment': 'map',
              'text-rotate': ['get', 'autocad_rotation'],
            },
            paint: { 'text-color': '#f7f5f7', 'text-opacity': 0.5 },
          });
        }

        mapbox.addLayer({
          id: 'gameLayer',
          type: 'symbol',
          source: 'slotSource',
          minzoom: 16,
          layout: {
            'text-field': ['to-string', ['get', 'game_name']],
            'text-max-width': 5.5,
            'text-size': [
              'interpolate',
              ['linear'],
              ['zoom'],
              16,
              3,
              17,
              5,
              18,
              9,
              18.5,
              10,
              19,
              21,
              20,
              30,
              24,
              60,
            ],
          },
          paint: {},
        });

        setMap(mapbox);
        setDisplayLegend(true);
        setIsMapInitialized(true);
      });
    };
    if (slotMapJsonWithIds !== undefined && heatBins !== undefined) {
      if (map) {
        setMap(map);
      }
      if (map === undefined) {
        initializeMap();
      }

      if (map) {
        if (isShowData) {
          updateMapData();
        }
      }
    } else {
      if (!geoJsonData && !getGeoJsonLoading) {
        if (map) {
          setMap(map);
          setIsDataLoaded(true);
        }
        if (map === undefined) {
          initializeMap();
        }
      }
    }
  }, [wallMapJson, labelMapJson, bankIdMapJson, heatBins, slotMapJsonWithIds]);

  useEffect(() => {
    if (isShowData) {
      updateMapData();
    }
  }, [map, slotMetaData?.DataSet, isTopTenSlot]);

  useEffect(() => {
    if (map) {
      setPreferenceHeatMapColor();
    }
  }, [heatFilter.join('')]);

  const setPreferenceHeatMapColor = () => {
    const newHeatFilter = [];

    for (let i = 6; i >= 1; i--) {
      const isIndexInHeatFilter = heatFilter.length > 0 && heatFilter.includes(i);
      const colorToUse = isIndexInHeatFilter
        ? heatBeanRatings[i as HeatBeanObjectKey].color
        : hexToRgba(heatBeanRatings[i as HeatBeanObjectKey].color, 0.2);
      newHeatFilter.push(['match', ['feature-state', 'heat'], [i], true, false]);
      newHeatFilter.push(colorToUse);
    }

    const defaultColor = heatBeanRatings[0].color;
    if (map) {
      map.setPaintProperty('slotLayer', 'fill-color', ['case', ...newHeatFilter, defaultColor]);
    }
  };

  return (
    <div className="home-container">
      <div className="floor-map-container">
        <div
          id={mapId}
          className={`${
            individualSlotNumber && !shouldHideSlotMark ? 'floor-map-individual' : ' '
          } floor-map ${
            (getGeoJsonLoading || isMapInitialized) && !slotDataIsLoading
              ? ''
              : 'floor-map--loading'
          }`}
          ref={(el) => (mapContainer.current = el)}
        >
          {displayLegend && (
            <HeatLegend
              onClick={(data: any) => {
                onClickHeatFilter(data);
              }}
              selectedInsight={selectedInsight}
              direction={DirectionType.vertical}
              heatFilter={[...heatFilter]}
            />
          )}
        </div>
        {!isDataLoaded && (
          <div className="loader-container">
            <div style={{ height: '100%', textAlign: 'center' }}>
              <SVGComponent message="Wait for it" />
            </div>
          </div>
        )}
        {loadError && (
          <div className="loader-container">
            <div style={{ height: '100%', textAlign: 'center' }}>
              <span style={{ paddingTop: '20%', display: 'block' }}>Error loading map.</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

FloorMap.defaultProps = {
  isShowData: true,
};
export default FloorMap;
